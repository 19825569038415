import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  brand?: string
  email?: string
  fax?: string
  frontOfficePhone?: string
  languageCode: string
  mobilePhone?: string
  phone?: string
  siteName?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  brand,
  email,
  fax,
  frontOfficePhone,
  languageCode,
  mobilePhone,
  phone,
  siteName,
}: Props) {
  return (
    <Container>
      {brand ? (
        <FadeInUp>
          <Brand style={{ backgroundImage: `url(${brand})` }} />
        </FadeInUp>
      ) : null}
      {siteName ? (
        <FadeInUp>
          <SiteName>{siteName}</SiteName>
        </FadeInUp>
      ) : null}
      {address ? (
        <FadeInUp>
          <Address>{address}</Address>
        </FadeInUp>
      ) : null}
      <FadeInUp>
        <Contacts dial={2} row wrap>
          {phone ? (
            <Phone
              href={`tel:${phone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'click', {
                      event_category: 'Website',
                      event_label: 'Phone Number',
                    })

                  typeof window._paq !== 'undefined' &&
                    window._paq.push([
                      'trackEvent',
                      'Website',
                      'Click',
                      'Phone Number',
                    ])
                }
              }}
            >
              {`${useVocabularyData('ph', languageCode)}: `}
              {phone}
            </Phone>
          ) : null}
          {mobilePhone ? (
            <MobilePhone
              href={`tel:${mobilePhone}`}
              onClick={() => {
                if (isMobile) {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'click', {
                      event_category: 'Website',
                      event_label: 'Mobile Phone Number',
                    })

                  typeof window._paq !== 'undefined' &&
                    window._paq.push([
                      'trackEvent',
                      'Website',
                      'Click',
                      'Phone Number',
                    ])
                }
              }}
            >
              {`${useVocabularyData('cell', languageCode)}: `}
              {mobilePhone}
            </MobilePhone>
          ) : null}
          {frontOfficePhone ? (
            <OfficePhone href={`tel:${frontOfficePhone}`}>
              {`${useVocabularyData('front-office', languageCode)}: `}
              {frontOfficePhone}
            </OfficePhone>
          ) : null}
          {fax ? (
            <Fax>
              {`${useVocabularyData('fax', languageCode)}: `}
              {fax}
            </Fax>
          ) : null}
        </Contacts>
      </FadeInUp>
      {email ? (
        <FadeInUp>
          <Email
            href={`mailto:${email}`}
            onClick={() => {
              typeof window.gtag !== 'undefined' &&
                window.gtag('event', 'click', {
                  event_category: 'Website',
                  event_label: 'Email Address',
                })

              typeof window._paq !== 'undefined' &&
                window._paq.push([
                  'trackEvent',
                  'Website',
                  'Click',
                  'Email Address',
                ])
            }}
          >
            {`${useVocabularyData('email', languageCode)}: `}
            {email}
          </Email>
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  margin: 6rem auto 6.875rem;
  padding: 0 10.972vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 0 1.9375rem;
  }

  @media (max-width: 1023px) {
    margin: 3.75rem auto 5.625rem;
  }
`

const Brand = styled.div`
  width: 4.5rem;
  height: 3.75rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto 1.125rem;

  @media (max-width: 1023px) {
    margin-bottom: 0.75rem;
  }
`

const SiteName = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 3.25rem;
  margin-bottom: 1.875rem;

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.4375rem;
    margin-bottom: 1.5rem;
  }
`

const Address = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
`

const Contacts = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;

  a {
    display: flex;
    align-items: center;
    &:hover {
      text-decoration: underline;
    }
    &:after {
      content: '';
      width: 0.3125rem;
      height: 0.3125rem;
      background: ${({ theme }) => theme.colors.variants.primaryLight1};
      margin: 0 0.75rem;
      transform: rotate(45deg);
    }
  }

  @media (max-width: 767px) {
    a {
      justify-content: center;
      width: 100%;
      &:after {
        display: none;
      }
    }
  }
`

const Phone = styled.a``

const MobilePhone = styled.a``

const OfficePhone = styled.a``

const Fax = styled.div`
  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Email = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.875rem;
  &:hover {
    text-decoration: underline;
  }
`
